<template>
    <div class="container">
        <div class="submit-style">
            <el-button :disabled="buttionArr.findIndex(f => f.code == 'add') < 0" size="mini" type="warning" circle
                disable="true" @click="onSubmit">保存</el-button>
        </div>
        <el-tabs v-model="activeName" type="card">
            <el-tab-pane name="first">
                <span slot="label"><i class="el-icon-setting"></i> 基础配置</span>
                <div class="box">
                    <el-form ref="form" :model="form" label-width="120px">
                        <el-form-item label="健康轮询时间">
                            <el-input-number size="mini" v-model="form.healthPollingTime" type="number"
                                style="width:8%;margin-left: 15px;">
                            </el-input-number>
                        </el-form-item>
                        <el-form-item label="异常通知">
                            <el-switch v-model="form.errMessageStatus" style="margin-left: 15px;" active-color="#13ce66"
                                inactive-color="#ff4949"> </el-switch>

                        </el-form-item>

                        <el-form-item label="网站置灰">
                            <el-switch v-model="form.htmlColor" style="margin-left: 15px;" active-color="#13ce66"
                                inactive-color="#ff4949"> </el-switch>
                        </el-form-item>
                        <el-form-item label="企业微信">
                            <span class="box-base-link">appId</span>
                            <el-input size="mini" v-model="form.enterpriseWeChatAppId" style="width:10%"></el-input>
                            <span class="box-base-link">secret</span>
                            <el-input size="mini" v-model="form.enterpriseWeChatSecret" style="width:10%"
                                :show-password="true"></el-input>
                        </el-form-item>

                        <el-form-item label="阿里云Oss">
                            <span class="box-base-link">appId</span>
                            <el-input size="mini" v-model="form.ossAppId" style="width:10%"></el-input>
                            <span class="box-base-link">secret</span>
                            <el-input size="mini" v-model="form.ossSecret" style="width:10%"
                                :show-password="true"></el-input>
                            <span class="box-base-link">总容量</span>
                            <el-input size="mini" v-model="form.ossTotal" style="width:8%;margin-top: 5px;"> <template
                                    slot="append">GB</template></el-input>
                        </el-form-item>

                        <el-form-item label="haproxy">
                            <span class="box-base-link">url</span>
                            <el-input size="mini" v-model="form.haproxyPath"
                                style="width:30%;margin-left: 20px;"></el-input>

                        </el-form-item>

                        <el-form-item label="客户端微服务">
                            <div style="margin-left: 15px;">
                                <el-tag :key="tag" v-for="tag in form.mhpClientsDisplay" closable
                                    :disable-transitions="false" @close="handleClose(tag)">
                                    {{ tag }}
                                </el-tag>
                                <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput"
                                    size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
                                </el-input>
                                <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New
                                    Tag</el-button>
                            </div>

                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
            <el-tab-pane label="" name="second">
                <span slot="label"><i class="el-icon-s-platform"></i> 服务器配置</span>
                <div class="box">

                    <el-form ref="form" :model="form" label-width="120px">
                        <div style="display: flex; justify-content: right;margin-right: 5%;margin-bottom: 10px;">

                            <el-button :disabled="buttionArr.findIndex(f => f.code == 'add') < 0" size="mini" type="primary"
                                icon="el-icon-plus" circle @click="addConnectParam">
                                新增 </el-button>
                        </div>
                        <el-tabs type="border-card">
                            <el-tab-pane v-for="(row, index)  in serverGroup" :key="index">
                                <span slot="label"><i class="el-icon-s-platform"></i> {{ index }}</span>
                                <div style="float: left;margin-left: 80px;">
                                    <el-card class="box-card" style="margin:10px 0px 0px 20px;float: left;"
                                        v-for="item in row" :key="item.key" :label="item.value" :value="item.value">
                                        <div slot="header" class="clearfix">
                                            <i class="el-icon-s-platform" style="color:orange;font-size: 25px;"></i> <span
                                                style="font-size: 20px;margin-left: 30px;">{{ item.label }}</span>
                                            <el-button :disabled="buttionArr.findIndex(f => f.code == 'del') < 0"
                                                style="float: right;" size="mini" type="danger" icon="el-icon-delete" circle
                                                @click="deleteConnectParam(item.label)">
                                            </el-button>
                                        </div>
                                        <div>
                                            <span class="box-base-link" style="background-color: orange;">归属</span>
                                            <el-input size="mini" v-model="item.group" style="width: 25%"
                                                maxlength="3"></el-input>

                                        </div>
                                        <div style="margin-top:10px">
                                            <span class="box-base-link">地址</span>
                                            <el-input size="mini" v-model="item.ip" class="card-frist"></el-input>
                                            <span class="box-base-link">日志</span>
                                            <el-select v-model="item.dayofLogs" placeholder="请选择" class="card-senced"
                                                size="mini">
                                                <el-option v-for="row in options" :key="row.value" :label="row.label"
                                                    :value="row.value">
                                                </el-option>
                                            </el-select>
                                            <span class="box-base-link">启用</span>
                                            <el-switch v-model="item.isEnable" style="margin: -3px 0px 0px 1px;"
                                                active-color="#13ce66" inactive-color="#ff4949"> </el-switch>

                                        </div>
                                        <div style="margin-top:10px">
                                            <span class="box-base-link">账号</span>
                                            <el-input size="mini" v-model="item.account" class="card-frist"></el-input>

                                            <span class="box-base-link">密码</span>
                                            <el-input :show-password="true" size="mini" v-model="item.passWord"
                                                class="card-senced">
                                            </el-input>
                                            <span class="box-base-link">端口</span>
                                            <el-input size="mini" v-model="item.port" class="card-three"></el-input>
                                        </div>

                                        <div style="margin-top:10px">
                                            <span class="box-base-link">宝塔</span>
                                            <el-input size="mini" v-model="item.baoTaPath" style="width: 56.5%"></el-input>
                                            <span class="box-base-link">排序</span>
                                            <el-input-number size="mini" v-model="item.sort" type="number" value="1"
                                                style="width: 15%;">
                                            </el-input-number>

                                        </div>
                                        <div style="margin-top:10px"
                                            v-if="item.label != 'prod-2' && item.label != 'prod-99'">
                                            <span class="box-base-link">队列</span>
                                            <el-input size="mini" v-model="item.rabbitWebPath"
                                                style="width: 56.5%"></el-input>

                                        </div>


                                    </el-card>
                                </div>
                            </el-tab-pane>


                        </el-tabs>
                        <div :hidden="form.serverAccounts != null" style="height:50px;text-align: center;">暂无数据</div>
                    </el-form>
                </div>
            </el-tab-pane>
            <el-tab-pane name="third">
                <span slot="label"><i class="el-icon-upload"></i> 服务器发布地址</span>
                <div class="box" style=" padding-left: 20px;margin-top: 20px;">
                    <el-form ref="form" :model="form" label-width="150px">
                        <el-form-item label="微服务器发布路径">
                            <el-input size="mini" v-model="form.publishPath" style="width:30%"></el-input>
                        </el-form-item>
                        <el-form-item label="微服务器jar包路径">
                            <el-input size="mini" v-model="form.jarPath" style="width:30%"></el-input>
                        </el-form-item>
                        <el-form-item label="归属">
                            <el-radio-group v-model="serverKey" size="mini" @input="changeServer">
                                <el-radio-button v-for="(item, index) in serverAcounts" :key="index" :label="item"
                                    :value="item">
                                </el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <span style="margin-left: 48%;">
                            <el-button :disabled="buttionArr.findIndex(f => f.code == 'add') < 0" size="mini" type="primary"
                                icon="el-icon-plus" circle @click="addWebPathParam">
                                新增</el-button>

                        </span>
                        <div style="width: 85vw;">

                            <el-tabs style="margin-top:30px" type="card">
                                <el-tab-pane v-for="(row, index)  in filterWebPath" :key="index">
                                    <span slot="label"><i class="el-icon-upload" style="margin-right: 5px;"></i>{{
                                        row[0].group }}- {{ index }}</span>

                                    <div v-for="item in row" :key="item.key" :label="item.value" :value="item.value">
                                        <el-form-item :label="item.key">
                                            <span class="box-base-link">名称</span>
                                            <el-input size="mini" v-model="item.key" style="width:6%"></el-input>
                                            <span class="box-base-link">环境</span>
                                            <el-input size="mini" v-model="item.env" style="width:4%"></el-input>
                                            <span class="box-base-link">ip</span>
                                            <el-input size="mini" v-model="item.ip" style="width:8%"></el-input>
                                            <span class="box-base-link">所属</span>
                                            <el-input size="mini" v-model="item.group" style="width:5%"></el-input>
                                            <span class="box-base-link">标识</span>
                                            <el-input size="mini" v-model="item.code" style="width:6%"></el-input>
                                            <span class="box-base-link">发布路径</span>
                                            <el-input size="mini" v-model="item.value" style="width:15%"></el-input>
                                            <span class="box-base-link">网站</span>
                                            <el-input size="mini" v-model="item.pageUrl" style="width:12%"></el-input>
                                            <span class="box-base-link">分组</span>
                                            <el-input-number size="mini" v-model="item.sort" type="number" value="1"
                                                style="width:6%">
                                            </el-input-number>
                                            <el-button :disabled="buttionArr.findIndex(f => f.code == 'del') < 0"
                                                style="margin-left: 12px;" size="mini" type="danger" icon="el-icon-delete"
                                                circle @click="deleteWebPathParam(item)"></el-button>
                                        </el-form-item>
                                    </div>

                                </el-tab-pane>
                            </el-tabs>
                        </div>



                    </el-form>
                </div>
            </el-tab-pane>
        </el-tabs>
        <div style="height:100px">

        </div>

    </div>
</template>

<script>
import requestApi from "@/api/system.js";
import common from "@/util/common.js";
import serverApi from "@/api/server.js";
export default {
    onload() {

    },
    created() {
        this.detail();
        this.getMenuButtions();
        this.getAccounts();

    },
    data() {
        return {
            form: {
                id: 0,
                healthPollingTime: 20,
                errMessageStatus: false,
                htmlColor: false,
                publishPath: '/home/push/mhp',
                jarPath: '/home/push/mhp/jar',
                webPath: [],

                enterpriseWeChatAppId: "",
                enterpriseWeChatSecret: "",
                aliyunSmsAppId: "",
                aliyunSmsSecret: "",
                aliyunOssAppId: "",
                aliyunOssSecret: "",
                mhpClients: null,
                mhpClientsDisplay: [],
                serverAccounts: []
            },
            //服务配置分组
            serverGroup: [],
            webPath: {},
            filterWebPath: {},
            buttionArr: [],
            options: [{
                value: '1',
                label: '1天'
            }, {
                value: '3',
                label: '3天'
            }, {
                value: '7',
                label: '7天'
            }, {
                value: '15',
                label: '15天'
            }, {
                value: '30',
                label: '30天'
            }],
            value: '',
            activeName: 'first',
            tableData: [],

            inputVisible: false,
            inputValue: '',
            serverKey: "全部",
            serverKeyArrays: [],
            serverAcounts: []
        }
    },
    methods: {
        changeServer(item) {
            if (item == "全部") {
                this.filterWebPath = this.webPath
            } else {
                this.serverKeyArrays = [],
                    this.serverKeyArrays.push(item)
                let filteredA = {};
                this.serverKeyArrays.forEach(key => {

                    for (let key in this.webPath) {
                        if (this.webPath.hasOwnProperty(key)) {

                            if (this.webPath[key][0].group === item) {
                                filteredA[key] = this.webPath[key];
                            }
                        }
                    }
                });
                this.filterWebPath = filteredA
            }

        },
        //获取权限关联按钮
        getMenuButtions() {
            this.buttionArr = JSON.parse(localStorage.getItem("menuButtions"));

        },
        detail() {
            let params = {};
            this.$get(requestApi.detail, params).then((res) => {
                if (res.success && res.statusCode == 200) {

                    this.form = res.data;
                    this.form.errMessageStatus = this.form.errMessageStatus == 0 ? false : true;
                    this.form.htmlColor = this.form.htmlColor == 0 ? false : true;
                    this.form.webPath = common.sortByKey(JSON.parse(this.form.webPath), "sort", "asc");
                    this.form.serverAccounts = common.sortByKey(JSON.parse(this.form.serverAccounts), "sort", "asc");
                    console.log(this.form.serverAccounts)
                    //服务器配置分组
                    const serverGroupedBy = {};
                    for (const item of this.form.serverAccounts) {
                        if (serverGroupedBy[item.group]) {
                            serverGroupedBy[item.group].push(item);
                        } else {
                            serverGroupedBy[item.group] = [item];
                        }
                    }
                    this.serverGroup = serverGroupedBy;
                    console.log(this.serverGroup)
                    //服务器发布地址分组
                    const groupedBy = {};
                    for (const item of this.form.webPath) {
                        if (groupedBy[item.code]) {
                            groupedBy[item.code].push(item);
                        } else {
                            groupedBy[item.code] = [item];
                        }
                    }
                    this.webPath = groupedBy;
                    document.documentElement.style.setProperty('--themeColor', '值');
                    this.changeServer(this.serverKey)
                }
            });
        },
        onSubmit() {
            this.$confirm('是否提交保存?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.opeater();

            }).catch(() => {

            });
        },
        opeater() {
            //操作新增和编辑
            let params = JSON.parse(JSON.stringify(this.form));
            params.webPath = JSON.stringify(params.webPath);
            params.serverAccounts = JSON.stringify(params.serverAccounts);
            this.$post(requestApi.add, params).then((res) => {
                if (res.success && res.statusCode == 200) {
                    this.detail();
                }

            });
        },
        addConnectParam() {
            this.$prompt('请输入标签', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputErrorMessage: '请输入标签'
            }).then(({ value }) => {
                console.log(value)
                if (value == null) {
                    this.$message({
                        type: 'info',
                        message: '请输入标签'
                    });
                    return;
                }
                let form = { "label": value, "ip": "", "account": "", "passWord": "", "port": "", "group": "1", "isEnable": false };
                this.form.serverAccounts.push(form);
                this.opeater();
            }).catch(() => {

            });
        },
        deleteConnectParam(index) {
            this.$confirm('此操作将删除：' + index + ' 的配置, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                let i = this.form.serverAccounts.findIndex((row) => {
                    return row.label == index;
                })
                this.form.serverAccounts.splice(i, 1)
                this.opeater();
            }).catch(() => {

            });
        },
        addWebPathParam() {
            this.$prompt('请输入标签 （ 标签名称含盖菜单增值权限名称 ）', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputErrorMessage: '请输入标签'
            }).then(({ value }) => {
                console.log(value)
                if (value == null) {
                    this.$message({
                        type: 'info',
                        message: '请输入标签'
                    });
                    return;
                }
                console.log(this.form.webPath)
                let maxSort = Math.max(...this.form.webPath.filter(f => f.value != "").map(p => p.sort));
                let form = { "key": value, "value": "", "pageUrl": "", env: "", code: "", "sort": maxSort + 1 };
                this.form.webPath.push(form);
                this.opeater();
            }).catch(() => {

            });
        },
        deleteWebPathParam(item) {
            console.log(item)
            this.$confirm('此操作将删除：' + item.code + "   " + item.key + ', 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let index = this.form.webPath.findIndex((row) => {
                    let temp = row.key == item.key && row.code == item.code
                    return temp;
                })
                this.form.webPath.splice(index, 1)
                this.opeater();
            }).catch(() => {

            });
        },
        handleClose(tag) {
            this.form.mhpClientsDisplay.splice(this.form.mhpClientsDisplay.indexOf(tag), 1);
        },

        showInput() {
            this.inputVisible = true;
            this.$nextTick(() => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },

        handleInputConfirm() {
            console.log(this.form)
            let inputValue = this.inputValue;
            if (inputValue) {
                this.form.mhpClientsDisplay.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';


        },
        getAccounts() {
            let params = {};
            this.$get(serverApi.getAccounts, params).then((res) => {
                if (res.success && res.statusCode == 200) {
                    this.serverAcounts = res.data;
                    const serverGroupedBy = [];
                    for (const item of this.serverAcounts) {
                        if (serverGroupedBy.findIndex(f => f === item.group) < 0) {
                            serverGroupedBy.push(item.group)
                        }
                    } 
                    this.serverAcounts = serverGroupedBy;
                    this.serverAcounts.unshift("全部")

                }
            });
        },
    }
}
</script>

<style scoped>
.container {
    padding: 20px;

}

.box {
    width: 100%;
    height: 100%;

}

.box-card {
    width: 600px;
    height: 300px;
}

.btn_box {
    position: fixed;
    top: 90%;
    left: 90%
}

.box-base-link {
    padding: 3px 10px;
    border-radius: 5px;
    margin: 0px 5px 0px 15px;
    background-color: #409EFF;
    color: white;

}

.submit-style {
    margin-bottom: 20px;
}

.el-tag+.el-tag {
    margin-left: 10px;
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}

.card-frist {
    width: 25%;
}

.card-senced {
    width: 19%;
}

.card-three {
    width: 15%;
}
</style>