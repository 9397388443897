const  sortByKey= function (array, key,sort){
    return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        if(sort=="desc"){
            return   ((x > y) ? -1 : ((x < y) ? 1 : 0));
        }else{
            return  ((x < y) ? -1 : ((x > y) ? 1 : 0));
        }
        
    })
}


/**
 * 时间日期格式化
 * @param format
 * @returns {*}
 */
 const dateFormat = function(dateObj, format) {
    const date = {
      'M+': dateObj.getMonth() + 1,
      'd+': dateObj.getDate(),
      'h+': dateObj.getHours(),
      'm+': dateObj.getMinutes(),
      's+': dateObj.getSeconds(),
      'q+': Math.floor((dateObj.getMonth() + 3) / 3),
      'S+': dateObj.getMilliseconds()
    }
    if (/(y+)/i.test(format)) {
      format = format.replace(
        RegExp.$1,
        (dateObj.getFullYear() + '').substr(4 - RegExp.$1.length)
      )
    }
    for (const k in date) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? date[k]
            : ('00' + date[k]).substr(('' + date[k]).length)
        )
      }
    }
    return format
  }


// 防抖
export function debounce(fn, delay) {
 
  var delay = delay || 200;
  var timer;
  return function () {
      var th = this;
      var args = arguments;
      if (timer) {
          clearTimeout(timer);
      }
      timer = setTimeout(function () {
          timer = null;
          fn.apply(th, args);
      }, delay);
  };
}
// 节流
export function throttle(fn, interval) {
  var last;
  var timer;
  var interval = interval || 200;
  return function () {
      var th = this;
      var args = arguments;
      var now = +new Date();
      if (last && now - last < interval) {
          clearTimeout(timer);
          timer = setTimeout(function () {
              last = now;
              fn.apply(th, args);
          }, interval);
      } else {
          last = now;
          fn.apply(th, args);
      }
  }
}

let  websocket=null;
import { Notification } from 'element-ui';


export function webSocketInfo(url){
    
    let userId = localStorage.getItem("userId")
    
    //userId = 登陆用户id
    //let browserId = this.getBrowserId();
    let clientId = "home_" + userId;
    if(websocket!=null){
      let data = { websocket: websocket , clientId:clientId}
      return data;
    }
    //判断当前浏览器是否支持WebSocket, 主要此处要更换为自己的地址
    if ('WebSocket' in window) {
      websocket = new WebSocket(url + clientId);
    } else {
      alert('Not support websocket')
    }

    //连接发生错误的回调方法
    websocket.onerror = function (event) {
      console.log("link error：" + event);
    };

    //连接成功建立的回调方法
    websocket.onopen = function (event) {
      console.log("webSocket " + clientId + " link ok.");
    }

    //接收到消息的回调方法
    websocket.onmessage = function (event) {
   
      //todo 准备的工作
      let data = JSON.parse(event.data)
      if (data.type === 3) {
        //3=网站置灰消息
        window.location.reload();
      }
      else if(data.type===4){
      
        Notification.warning({
          title: '',
          message: data.message,
          position: 'bottom-right',
          duration: 3000
        });
      }
      

    }
    //连接关闭的回调方法
    websocket.onclose = function (event) {
      console.log("webSocket link close.")
    }
    //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
    window.onbeforeunload = function () {
      websocket.close();
    }

  
}

 


export default {
    sortByKey,webSocketInfo,dateFormat
  }