const getAccounts= "/system/server/accounts";
const getServerInfo = "/system/server/getServerInfo";
const getCpuLineCharts = "/system/server/cpuLineCharts";
const getMemoryLineCharts = "/system/server/memoryLineCharts";
const getOssInfo = "/system/server/getOssInfo";


export default {
  getAccounts,
  getServerInfo,
  getCpuLineCharts,
  getMemoryLineCharts,
  getOssInfo
}